<template>
  <div class="main">
    <div class="container">
      <p class="title blueText">{{ $t('company.autopayment') }}</p>
      <div class="card blueText">
        <p>
          {{ $t('map.title') }}
          <span class="ml-2">
            <el-tooltip
              :offset="0"
              :visible-arrow="false"
              :open-delay="700"
              effect="dark"
              placement="top"
              :content="
                cardHolder.name
                  ? cardHolder.name + ' (' + cardHolder.email + ')'
                  : ''
              "
            >
              <span>
                <InfoIcon />
              </span>
            </el-tooltip>
          </span>
        </p>
      </div>
    </div>
    <div class="mt-2 card-container">
      <el-checkbox
        class="checkbox"
        v-model="localIsAutoPayActive"
        :label="$t('company.autopayment_on')"
        :disabled="!isHaveAccess || !cardSuffix"
      />

      <div class="creditCard" v-if="isCardExist">
        <span :class="isCardActive">**** **** **** {{ cardSuffix }}</span>
        <span class="editCard">
          <span @click="editCard">
            <EditCard />
          </span>
          <span @click="handleDeleteCard">
            <DeleteCard />
          </span>
        </span>
      </div>
      <div class="noCard" v-else>
        <p class="mr-2">{{ $t('company.no') }}</p>
        <span @click="addCard" class="addCard">
          <AddCard />
        </span>
      </div>
    </div>
    <div v-if="currentPayementError" class="danger-container infoMessage">
      <p>
        {{ currentPayementError }}
      </p>
    </div>
    <div v-if="errorMessage" class="warning-container infoMessage">
      <p>
        {{ errorMessage }}
      </p>
    </div>
    <el-dialog
      custom-class="pay-dialog"
      :visible.sync="isEditCard"
      :modalAppendToBody="false"
      :close-on-click-modal="false"
      :show-close="false"
      :title="$t('company.change_card_autopayment')"
    >
      <div class="body">
        <p>
          {{ $t('company.change_card_cost_note') }}
        </p>
        <div class="cards-container mt-5">
          <span class="card-choose">
            {{ $t('company.choose_payment_system') }}
          </span>
          <el-radio
            class="card-radio mt-4"
            v-model="cardType"
            width="20"
            :label="$t('company.visa_or_mastercard')"
          />
          <div class="mir-container mb-2">
            <el-radio
              class="mt-4 card-radio"
              v-model="cardType"
              width="20"
              :label="$t('company_system_mir')"
            />
            <span class="info-card">
              {{ $t('company.temporarily_unavailable') }}
            </span>
          </div>
          <span class="txt-danger" v-if="isMirError">
            {{ $t('company.autopayment_unavailable_with_mir') }}
          </span>
        </div>
      </div>
      <div slot="footer" class="footer">
        <span class="cancelBtn" @click="isEditCard = false">{{
          $t('company.cancel')
        }}</span>
        <span class="acceptBtn" @click="handleEditCard">{{
          $t('company.continue')
        }}</span>
      </div>
    </el-dialog>
    <el-dialog
      custom-class="pay-dialog"
      :visible.sync="isAddCard"
      :modalAppendToBody="false"
      :close-on-click-modal="false"
      :show-close="false"
      :title="$t('company.card_add_for_autopayments')"
    >
      <div class="body">
        <p>
          {{
            $t('company.add_card_cost_note') +
            me.active_company.billing.system.min_pay_amount +
            $t('tariff.rouble')
          }}
        </p>
        <div class="cards-container mt-5">
          <div class="card-choose">
            *{{ $t('company.choose_payment_system') }}:
          </div>
          <el-radio
            class="card-radio mt-4"
            v-model="cardType"
            width="20"
            :label="$t('company.visa_or_mastercard')"
          />
          <div class="mir-container mb-2">
            <el-radio
              class="mt-4 card-radio"
              v-model="cardType"
              width="20"
              :label="$t('company_system_mir')"
            />
            <span class="info-card">
              ({{ $t('company.temporarily_unavailable') }})
            </span>
          </div>
          <span class="txt-danger" v-if="isMirError">
            {{ $t('company.autopayment_unavailable_with_mir') }}
          </span>
        </div>
      </div>
      <div slot="footer" class="footer">
        <span class="cancelBtn" @click="isAddCard = false">{{
          $t('company.cancel')
        }}</span>
        <span class="acceptBtn" @click="handleAddCard">{{
          $t('company.continue')
        }}</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    EditCard: () => import('../assets/EditCard.vue'),
    DeleteCard: () => import('../assets/DeleteCard.vue'),
    AddCard: () => import('../assets/AddCard.vue'),
    InfoIcon: () => import('../assets/InfoIcon.vue')
  },
  props: ['activeCompany', 'currentPayementError', 'isAutoPayActive'],
  data() {
    return {
      isMirError: false,
      cardType: 'Visa или Mastercard',
      isCardExist: false,
      cardSuffix: '',
      isEditCard: false,
      isAddCard: false,
      isHaveAccess: false,
      errorMessage: null,
      cardHolder: '',
      localIsAutoPayActive: this.isAutoPayActive
    }
  },
  computed: {
    ...mapGetters('login', ['role', 'me']),
    isCardActive() {
      return this.isAutoPayActive ? 'cardActive' : 'cardNotActive'
    }
  },
  watch: {
    cardType(val) {
      if (val === 'Система МИР') {
        this.isMirError = true
      } else {
        this.isMirError = false
      }
    },
    localIsAutoPayActive(val) {
      if (this.isHaveAccess) {
        this.$emit('autoPay', val)
      }
    }
  },
  methods: {
    handleAddCard() {
      this.isAddCard = false
      this.$emit('addCard')
    },
    handleEditCard() {
      if (!this.isMirError) {
        this.isEditCard = false
        this.$emit('editCard')
      }
    },
    handleDeleteCard() {
      this.$emit('deleteCard')
      this.isCardExist = false
      this.cardSuffix = ''
      this.localIsAutoPayActive = false
    },
    editCard() {
      this.isEditCard = true
    },
    addCard() {
      this.isAddCard = true
    }
  },
  mounted() {
    const autoPay = this.activeCompany.billing.auto_pay
    // const allowedRoles = ['ADMIN', 'SUPERVISOR', 'TARIFF_EDITOR', 'CONTROLLER']
    this.isHaveAccess = this.role === 'ADMIN'

    if (autoPay.card_num_suffix != null) {
      this.isCardExist = true
      this.cardSuffix = autoPay.card_num_suffix

      this.errorMessage = autoPay.error_msg
      this.cardHolder = { name: autoPay.user_name, email: autoPay.user_email }
    } else {
      this.isCardExist = false
    }
  }
}
</script>

<style lang="scss">
.cards-container {
  .card-choose {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #7fabe1;
  }
  .card-radio span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2b2d;
  }
  .txt-danger {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #df4747;
  }
}
.infoMessage {
  padding: 10px;
  border-radius: 3px;
  margin: 10px 0;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* or 117% */

    letter-spacing: 0.1px;
  }
}
.danger-container {
  background-color: #ffdede;
  p {
    color: #df4747;
  }
}
.warning-container {
  background-color: #fff1e9;
  p {
    color: #df7d47;
  }
}
.main {
  .container {
    display: flex;
    align-items: center;
    width: 100%;
    .blueText {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #80abe0;
    }
    .title {
      flex: 1;
    }
    .card {
      flex: 1;
    }
  }
}
.card-container {
  display: flex;
  justify-content: center;
  .checkbox {
    flex: 1;
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 124px;
      line-height: 18px;
    }
  }
  .creditCard {
    flex: 1;
    display: flex;
    justify-content: center;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      margin-right: 8px;
      flex: 3;
    }
    .editCard {
      flex: 1;
      cursor: pointer;
    }
    .cardActive {
      color: black;
      transition: color 0.2s ease-in-out;
      margin-right: 6px;
      padding-right: 0;
    }
    .cardNotActive {
      color: #b0b0b0;
      transition: color 0.2s ease-in-out;
      margin-right: 6px;
      padding-right: 0;
    }
  }
  .noCard {
    flex: 1;
    display: flex;
    .addCard {
      cursor: pointer;
    }
  }
}
.pay-dialog {
  width: 420px;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #80abe0;
  }
  .el-dialog__body {
    padding: 0px 20px 16px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2b2d;
    word-break: keep-all;
  }
  .footer {
    margin-top: 16px;
    .cancelBtn {
      color: #446c9d;
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
    .acceptBtn {
      padding: 7px 26px;
      background: #446c9d;
      border-radius: 6px;
      color: white;
      margin-left: 24px;
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
